<template>
    <el-autocomplete
        v-model="NumeAngajatMutable"
        :fetch-suggestions="querySearch"
        placeholder="Cauta"
        @select="handleAngajatSelect"
        @input="onManualinput"
        :disabled="dezactivat"
        class="full-width">
        <template slot-scope="{ item }"> 
            <span class="value">{{ item.Nume }}</span>
            <span v-if="arataInfo" style="float:right; color:#8492a6; font-size: 13px">{{ item.Info }}</span>
            <span v-if="arataTipul" style="float:right; color:#dcdfe6; font-size: 13px">{{ item.Tip }}</span>
        </template>
    </el-autocomplete>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage'

    export default {
        name: "SelectAngajat",
        extends: BasePage,
        props: {
            value: {
                required: true,
            },
            IdAngajat:{
                required:false
            },
            dezactivat: {
                required: false,
                default () {
                    return false
                }
            },
            arataInfo: {
                type: Boolean,
                required: false,
                default () {
                    return false
                }
            }, 
            arataTipul: {
                type: Boolean,
                required: false,
                default () {
                    return false
                }
            },
            ExcludePresedinteVicepresedinte: {
                required: false,
                type: Boolean,
                default () {
                    return false
                }
            },            
            arataDoarAngajatiEligibiliProiecte: {
                required: false,
                type: Boolean,
                default () {
                    return false
                }
            },
            TipAngajati: {
                required: false,
                type: String,
                default () {
                    return ''
                }
            },
            ArataOptiuneToti: {
                required: false,
                type: Boolean,
                default () {
                    return false
                }
            }           
        },
        
        computed: {
            NumeAngajatMutable: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
        watch: {
            NumeAngajatMutable: function(newVal) {
                this.$emit('input', newVal)
            },
            NumeAngajat: function(newVal) {
                this.$set(this, 'NumeAngajatMutable', newVal)
            }
        },
        data(){
            return {
                lastSelected: null
            }        
        },

        methods: {
            async querySearch(queryString, cb) {
                if (!queryString) {
                    queryString = ''
                }
                
                var response = await this.post('angajati/search_nume_angajat', {
                    queryString: queryString,
                     "inclusivPresedinteVicepresedinte": this.ExcludePresedinteVicepresedinte,
                     "arataDoarAngajatiEligibiliProiecte": this.arataDoarAngajatiEligibiliProiecte,
                     "TipAngajati": this.TipAngajati,
                     
                })

                cb(response.Angajati)
            },
            handleAngajatSelect: function (item) {
                var angajatObj          = item.AngajatComplet
                this.lastSelected       = angajatObj
                this.NumeAngajatMutable = item.Nume
                
                this.$emit('update:IdAngajat',item.id)                
                this.$emit('selectie', item)
            },
            onManualinput(value) {
                if (this.lastSelected && value == this.lastSelected.Nume) {
                    this.$emit('update:IdAngajat', this.lastSelected.id)                 
                    return
                }

                this.$emit('update:IdAngajat', undefined)              
            }
        },
        mounted(){  
        } 
    }
</script>

<style lang="less" scoped>
  .full-width{
    width: 100%;
  }

  .el-autocomplete-suggestion li {
    border-bottom:1px solid #b4b4b4;
  }

</style>
