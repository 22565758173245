<template>
    <el-autocomplete
        v-model="NumeDepartamentMutable"
        :fetch-suggestions="querySearch"
        placeholder="Cauta"
        @select="handleDepartamentSelect"
        @input="onManualinput"
        :disabled="dezactivat"
        class="full-width">
        <template slot-scope="{ item }"> 
            <span class="value">{{ item.Nume }}</span>
            <span style="float:right; color:#8492a6; font-size: 13px">{{ item.Tip }}</span>
        </template>
    </el-autocomplete>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage'

    export default {
        name: "SelectDepartament",
        extends: BasePage,
        props: {
            value: {
                required: true,
            },
            dezactivat: {
                required: false,
                default () {
                    return false
                }
            },        
        },
        
        computed: {
            NumeDepartamentMutable: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
        watch: {
            NumeDepartamentMutable: function(newVal) {
                this.$emit('input', newVal)
            },
            NumeDepartament: function(newVal) {
                this.$set(this, 'NumeDepartamentMutable', newVal)
            }
        },
        data(){
            return {
                lastSelected: null
            }        
        },

        methods: {
            async querySearch(queryString, cb) {
                if (!queryString) {
                    queryString = ''
                }
                console.log(queryString)

                var response = await this.post('departamante/search_item', {
                    queryString: queryString    
                })

                console.log(response)

                cb(response.Departamente)
            },
            handleDepartamentSelect: function (item) {
                
                this.NumeDepartamentMutable = item.Nume
                
                this.$emit('update:IdDepartament',item.id)                
                this.$emit('selectie', item)
            },
            onManualinput(value) {
                if (this.lastSelected && value == this.lastSelected.Nume) {
                    this.$emit('update:IdAngajat', this.lastSelected.id)                 
                    return
                }

                this.$emit('update:IdAngajat', undefined)              
            }
        },
        mounted(){  
        } 
    }
</script>

<style lang="less" scoped>
  .full-width{
    width: 100%;
  }

  .el-autocomplete-suggestion li {
    border-bottom:1px solid #b4b4b4;
  }

</style>
