import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        { path: '/'                                 , name: 'home'                              , component: () => import( './pages/Login.vue'                                                                  ) },
        { path: '/dashboard'                        , name: 'dashboard'                         , component: () => import( './pages/Dashboard.vue'                                                              ) },
        { path: '/categorii-drepturi'               , name: 'categorii-drepturi'                , component: () => import ('./pages/categorii-drepturi/Categorii-drepturi_list.vue'                             ) },
        { path: '/drepturi'                         , name: 'drepturi'                          , component: () => import ('./pages/drepturi/Drepturi_list.vue'                                                 ) },
        { path: '/grupuri-utilizatori'              , name: 'grupuri-utilizatori'               , component: () => import ('./pages/grupuri-utilizatori/Grupuri-utilizatori_list.vue'                           ) },
        { path: '/utilizatori'                      , name: 'utilizatori'                       , component: () => import ('./pages/utilizatori/Utilizatori_list.vue'                                           ) },
        { path: '/profil-utilizator'                , name: 'profil_utilizator'                 , component: () => import ('./pages/utilizatori/Profil_utilizator.vue'                                          ) },
        { path: '/angajati'                         , name: 'angajati'                          , component: () => import( './pages/angajati/Angajati_list.vue'                                                 ) },
        { path: '/functii_angajati'                 , name: 'functii_angajati'                  , component: () => import( './pages/functii_angajati/Functii_angajati_list.vue'                                 ) },
        { path: '/directii'                         , name: 'directii'                          , component: () => import( './pages/directii/Directii_list.vue'                                                 ) },
        { path: '/servicii'                         , name: 'servicii'                          , component: () => import( './pages/servicii/Servicii_list.vue'                                                 ) },
        { path: '/compartimente'                    , name: 'compartimente'                     , component: () => import( './pages/compartimente/Compartimente_list.vue'                                       ) },
        { path: '/departamante'                     , name: 'departamante'                      , component: () => import( './pages/departamante/Departamante_list.vue'                                         ) },
        { path: '/grupe_de_organizare'              , name: 'grupe_de_organizare'               , component: () => import( './pages/grupe_de_organizare/Grupe_de_organizare_list.vue'                           ) },
        { path: '/utilizatori_grupe'                , name: 'utilizatori_grupe'                 , component: () => import( './pages/utilizatori_grupe/Utilizatori_grupe_list.vue'                               ) },
        { path: '/raport_organizare'                , name: 'raport_organizare'                 , component: () => import( './pages/raport_organizare/Raport_organizare.vue'                                    ) },
        { path: '/raport_actiuni'                   , name: 'raport_actiuni'                    , component: () => import( './pages/raport_actiuni/Raport_actiuni.vue'                                          ) },
        { path: '/raport_financiar'                 , name: 'raport_financiar'                  , component: () => import( './pages/raport_financiar/Raport_financiar.vue'                                      ) },
        { path: '/raport_incarcare'                 , name: 'raport_incarcare'                  , component: () => import( './pages/raport_grad_incarcare/Raport_grad_incarcare.vue'                            ) },
        { path: '/raport_proiecte'                  , name: 'raport_proiecte'                   , component: () => import( './pages/raport_proiecte/Raport_proiecte.vue'                                        ) },        
        { path: '/proiecte'                         , name: 'proiecte'                          , component: () => import( './pages/proiecte/Proiecte_list.vue'                                                 ) },
        { path: '/harti'                            , name: 'harti'                             , component: () => import( './pages/harti/Harti_list.vue'                                                       ) },
        { path: '/proceduri'                        , name: 'proceduri'                         , component: () => import( './pages/proceduri/Proceduri.vue'                                                    ) },
        { path: '/registratura'                     , name: 'registratura'                      , component: () => import( './pages/registratura/Registratura.vue'                                              ) },
        { path: '/calcul_sporuri'                   , name: 'calcul_sporuri'                    , component: () => import( './pages/calcul_sporuri/Calcul-sporuri_list.vue'                                     ) },
        { path: '/planuri_management'               , name: 'planuri_management'                , component: () => import( './pages/planuri_management/Planuri-management_list.vue'                             ) },
        { path: '/arii_protejate'                   , name: 'arii_protejate'                    , component: () => import( './pages/arii_protejate/Arii-protejate_list.vue'                                     ) },
        { path: '/angajati_activitati'              , name: 'angajati_activitati'               , component: () => import( './pages/angajati_activitati/Angajati-activitati_list.vue'                           ) },
        { path: '/ore_angajati'                     , name: 'ore_angajati'                      , component: () => import( './pages/ore_angajati/Ore-angajati_list.vue'                                         ) },
        { path: '/membri_comisie_evaluare'          , name: 'membri_comisie_evaluare'           , component: () => import( './pages/membri_comisie_evaluare/Membri_comisie_evaluare_list.vue'                   ) },
        { path: '/istoric_membri_comisie_evaluare'  , name: 'istoric_membri_comisie_evaluare'   , component: () => import( './pages/istoric_membri_comisie_evaluare/Istoric_membri_comisie_evaluare_list.vue'   ) },
        { path: '/comisii_executare'                , name: 'comisii_executare'                 , component: () => import( './pages/comisii_executare/Comisii_executare_list.vue'                               ) },
        { path: '/bunuri_preluate'                  , name: 'bunuri_preluate'                   , component: () => import( './pages/bunuri_preluate/Bunuri_preluate_list.vue'                                   ) },
        { path: '/istoric_membri_comisie_executare' , name: 'istoric_membri_comisie_executare'  , component: () => import( './pages/istoric_membri_comisie_executare/Istoric_membri_comisie_executare_list.vue' ) },
        { path: '/pontaj'                           , name: 'pontaj'                            , component: () => import( './pages/pontaj/Pontaj_list.vue'                                                     ) },
        { path: '/pontaj_institutie'                , name: 'pontaj_institutie'                 , component: () => import( './pages/pontaj_institutie/Pontaj_institutie_list.vue'                               ) },
        { path: '/zile_libere'                      , name: 'zile_libere'                       , component: () => import( './pages/zile_libere/Zile_libere_list.vue'                                           ) },
        { path: '/raport_planuri_pe_arii'           , name: 'planuri_pe_arii'                   , component: () => import( './pages/raport_planuri_pe_arii/Raport_planuri_pe_arii.vue'                          ) },
        { path: '/concedii'                         , name: 'concedii'                          , component: () => import( './pages/concedii/Concedii_list.vue'                                                 ) },
        { path: '/concedii_tipuri'                  , name: 'concedii_tipuri'                   , component: () => import( './pages/concedii_tipuri/Concedii_tipuri_list.vue'                                   ) },
        { path: '/template_formulare'               , name: 'template_formulare'                , component: () => import( './pages/template_formulare/Template_formulare_list.vue'                             ) },
        { path: '/raport_formulare'                 , name: 'raport_formulare'                  , component: () => import( './pages/raport_formulare/Raport_formulare_list.vue'                                 ) },
        { path: '/template_header'                  , name: 'template_header'                   , component: () => import( './pages/template_formulare/Template_header.vue'                                     ) },
        { path: '/formular'                         , name: 'formular'                          , component: () => import( './pages/formular/Formular_list.vue'                                                 ) },
        { path: '/setari'                           , name: 'setari'                            , component: () => import( './pages/setari/Setari_list.vue'                                                     ) },
        { path: '/dosare_deschise'                  , name: 'dosare_deschise'                   , component: () => import( '@/pages/dosare_deschise/Dosare_deschise_list.vue'                                   ) },
        { path: '/avizari_formulare_list'           , name: 'avizari_formulare_list'            , component: () => import( '@/pages/avizari_formulare/Avizari_formulare_list.vue'                               ) },
        { path: '/variabile'                        , name: 'variabile'                         , component: () => import( '@/pages/variabile/Variabile_list.vue'                                               ) },
    ]
})
