import Vue from "vue";

export default {
    version: "3.3",
    ProcentSporSalarii: 15,
    BASE_URL_DEV: 'http://ananp.brk-dev.ro/api/index.php/',
    BASE_URL_PROD: 'http://192.168.88.101:8080/api/index.php/',
    BASE_URL: '',

    set_token: function(newToken) {
        window.localStorage.setItem("ananp-token", newToken);
        Vue.http.headers.common["Token"] = newToken;
    },

    get_token: function() {
        return window.localStorage.getItem("ananp-token");
    },

    set_drepturi: function (drepturi) {
        window.localStorage.setItem('ananp-drepturi_user', JSON.stringify(drepturi));
    },

    get_drepturi: function () {
        var drepturi = window.localStorage.getItem('ananp-drepturi_user');
        return JSON.parse(drepturi);
    },
    set_user_type: function (user_type) {
        window.localStorage.setItem('ananp-user_type', JSON.stringify(user_type));
        // window.localStorage.setItem('ananp-user_type', user_type);
    },

    set_user_profile_image_url: function( url ){
        window.localStorage.setItem('user_profile_image_url', url);
    },

    get_user_profile_image_url: function(){
        return window.localStorage.getItem('user_profile_image_url');
    },

    set_user_id: function(id){
        window.localStorage.setItem('user_id', id);
    },

    get_user_id: function(){
        return window.localStorage.getItem('user_id');
    },

    get_user_type: function () {
        // return window.localStorage.getItem('ananp-user_type');


        var tipuri_utilizator = window.localStorage.getItem('ananp-user_type');
        return JSON.parse(tipuri_utilizator);

    },

    get_user_name: function(){
        return window.localStorage.getItem('ananp-user_name');
    },

    set_user_name: function(user_name){
        window.localStorage.setItem('ananp-user_name', user_name);
    },

    set_job_title: function (job_title) {
        window.localStorage.setItem('ananp-job_title', job_title);
    },

    get_job_title: function () {
        return window.localStorage.getItem('ananp-job_title');
    },

    is_logged_in: function() {
        var token = this.get_token();
        return token !== "";
    },

    logout: function() {
        this.set_token('');
        this.set_drepturi('');
    },

    verify_response: function(response) {
        if (response.body.NotLogged) {
            return false;
        }
        return true;
    },

    verify_login_and_redirect: function(vueInstance) {
        if (!this.is_logged_in()) {
            vueInstance.$router.push("/");
        }
    },

    getIndexOfArrayProperty(paramArray, paramProperty,paramvalue){			
        const search = obj => obj[paramProperty] === paramvalue;
        return paramArray.findIndex(search)
    },
    moveIndexToEnd(paramArray,paramIndex){
        let tempArray = paramArray.map(o => ({...o}));
        let indexToMove = tempArray.splice(paramIndex,1);          
        tempArray.push(indexToMove[0]);        
        return tempArray;
    },
};
