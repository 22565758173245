<template>
    <div>   
        <el-input-number class='full-width' v-model="rand.NumarOre" :min="1" :max="templateOre.MaximOre" ></el-input-number>
        <div v-show="Results.length > 0">
            <el-popover
                placement="top"
                trigger="hover">
                <el-row>
                    <span style="color:red">Angajatul mai este implicat in urmatoarele activitati:</span>
                </el-row>
                <el-table :data="Results">
                    <el-table-column width="50" property="NumarOre" label="Ore"></el-table-column>
                    <el-table-column width="200" property="NumeProiect" label="Proiect"></el-table-column>
                    <el-table-column width="200" property="NumeActivitate" label="Activitate"></el-table-column>
                    <el-table-column width="200" property="NumeSubactivitate" label="Subactivitate"></el-table-column>
                </el-table>
                <el-button size="mini" type="danger" icon="el-icon-info" slot="reference" rounded></el-button>
            </el-popover>
        </div>
    </div>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage'

    export default {
        name: "SelectOre",
        extends: BasePage,
        // watch: {
        //     rand(val){
        //         this.refresh_info();
        //         console.log('rand')
        //     }

        // },

        data(){
            return {
                Results: [],
                templateOre: {  NumarOreAlteProiecte: 0, MaximOre:0 },
                rand: {},
                // IdAngajat: '',
                // DataStart: '',
                // DataStop: '',
            }        
        },   
        methods: {
            async get_ore_alte_proiecte(rand_primit)
            {
                var response = await this.post('pontaj/get_maxim_ore', {
                    rand: rand_primit
                })

                this.rand = rand_primit;
 
                this.Results                            = response.AlteProiecte;
                this.templateOre.NumarOreAlteProiecte   = response.TotalOre;
                this.templateOre.MaximOre               = response.MaxOreZiTipAngajat - this.templateOre.NumarOreAlteProiecte;

            },           
            
            // handleChange(value) {
            //     // refresh_info();


            //     console.log(value)

            // }
            
        },
        created(){  
            // this.refresh_info();
            // console.log('creata')
        } 
    }
</script>

<style lang="less" scoped>
  .full-width{
    width: 100%;
  }

</style>
