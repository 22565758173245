<template>
<div>
    <b-navbar class='nav-on-top' toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand href="#" >
            <img src="../assets/logo_ananp_mic.png" alt="logo" style="max-height: 40px;" />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse is-nav id="nav_collapse" class="menu-bar">
            <!-- <b-navbar-nav v-if="$is_user_type('Admin') || $is_user_type('Manager de proiect') || $is_user_type('Supervisor') "> -->
            <b-navbar-nav>
                <b-nav-item href='#/formular' class='formular'> Formular nou </b-nav-item>

                <!-- start aici inseram restul de elemente din meniu -->                   
                                    
                <b-nav-item-dropdown class='setari' text="Setari" left>
                    <b-dropdown-item href='#/angajati' class='angajati'> Angajati </b-dropdown-item>
                    <b-dropdown-item href='#/functii_angajati' class='functii_angajati'> Functii angajati </b-dropdown-item>
                    <b-dropdown-item href='#/grupe_de_organizare' class='grupe_de_organizare'> Grupe de organizare </b-dropdown-item>
                    <b-dropdown-item href='#/proiecte' class='proiecte'>Proiecte</b-dropdown-item>
                    <b-dropdown-item href='#/harti' class='harti'> Setari harti </b-dropdown-item>
                    <b-dropdown-item v-if="$has_right('vizualizare-setari-formulare')" href='#/template_formulare' class='template_formulare'> Setari Formulare </b-dropdown-item>   
                    <b-dropdown-item href='#/template_header' class='template_header'> Setari Antet/Subsol </b-dropdown-item>
                    <b-dropdown-item href='#/setari' class='setari'> Setari pontaj </b-dropdown-item>                    
                </b-nav-item-dropdown>

                <b-nav-item-dropdown text="Lista arii" right>
                    <b-dropdown-item href='#/planuri_management' class='planuri_management'> Planuri management </b-dropdown-item>
                    <b-dropdown-item href='#/arii_protejate' class='arii_protejate'> Arii protejate </b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item-dropdown text="Pontaj" class='pontaj' right>
                    <b-dropdown-item href='#/pontaj' class='pontaj'>Pontaj proiecte</b-dropdown-item>
                    <b-dropdown-item href='#/pontaj_institutie' class='pontaj_institutie'>Pontaj Institutie</b-dropdown-item>
                </b-nav-item-dropdown>
                
                <b-nav-item href='#/avizari_formulare_list' class='avizari_formulare_list'> Avizari formulare </b-nav-item>

                <b-nav-item-dropdown text="Rapoarte" class='rapoarte' right>
                    <b-dropdown-item href='#/raport_organizare' class='raport_organizare'> Raport organizare </b-dropdown-item>
                    <b-dropdown-item v-if="$has_right('vizualizare-raport-formulare')" href='#/raport_actiuni' class='raport_proiecte'> Raport actiuni </b-dropdown-item>                   
                </b-nav-item-dropdown> 
                <b-nav-item href='#/proceduri' class='proceduri'> Proceduri </b-nav-item>
                <b-nav-item href='#/registratura' class='registratura'> Registratura </b-nav-item>
                <b-nav-item-dropdown text="Harti" class='harti' right>
                    <b-dropdown-item v-for="harta in harti" :key="harta.Id" :href="harta.Link" target="_blank" class='harta'> 
                        {{harta.Nume}}
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <!-- end aici inseram restul de elemente din meniu -->
            </b-navbar-nav> 

            <b-navbar-nav is-nav class="menu-bar">    
                <b-nav-item-dropdown v-if="$is_user_type('SRU') || $is_user_type('Admin')" text="Concedii" class='rapoarte'>
                    <b-dropdown-item href='#/zile_libere' class='zile_libere'> Zile libere </b-dropdown-item>
                    <b-dropdown-item href='#/concedii_tipuri' class='concedii_tipuri'> Tipuri concedii </b-dropdown-item>
                    <b-dropdown-item href='#/concedii' class='concedii'> Concedii si deplasari </b-dropdown-item>     
                </b-nav-item-dropdown>                    
            </b-navbar-nav>                        

            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown v-if="$is_user_type('Admin')" text="Utilizatori si drepturi" right>
                    <b-dropdown-item href="#/utilizatori">Utilizatori</b-dropdown-item>
                    <b-dropdown-item href="#/grupuri-utilizatori">Grupuri utilizatori</b-dropdown-item>
                    <b-dropdown-item href="#/categorii-drepturi">Categorii drepturi</b-dropdown-item>
                    <b-dropdown-item href="#/drepturi">Drepturi</b-dropdown-item>                    
                </b-nav-item-dropdown>

                <b-nav-item-dropdown :text="user_name + ' (' + job_title + ')'">
                    <b-dropdown-item href="#/profil-utilizator">Profil utilizator</b-dropdown-item>
                    <b-dropdown-item @click="change_password()"> Schimbare parola</b-dropdown-item>
                    <b-dropdown-item @click="onLogout()" right> Iesire cont</b-dropdown-item>
                </b-nav-item-dropdown >    

                <b-nav-item href="#" disabled><span class="version">V {{version}}</span></b-nav-item>            
            </b-navbar-nav>
        </b-collapse>
        
    </b-navbar>
    <change-password-dialog ref="changePassDlg"></change-password-dialog>
</div>

</template>

<script>

import settings from '@/backend/LocalSettings';
import ChangePassword_dialog from '@/pages/ChangePassword_dialog';

export default {
    name: 'Login',
    data () {
        return {
            user_type: '',
            user_name: '',
            version: '',
            job_title:'',
            harti: [],
        }
    },
    components: {
        'change-password-dialog': ChangePassword_dialog
    },
    methods:
        {
            post: async function(url, args={}){
                this.loadingVisible      = true;
                var             response = await this.$http.post(url, args );
                this.loadingVisible      = false;
                
                if( settings.verify_response(response) )
                    return response.body;
                else
                    this.$router.push("/");
            },

            getHarti: async function() {
                let response = await this.post("harti");
                this.harti   = response.Results;
            },

            change_password: function(){
                this.$refs['changePassDlg'].show_me();
            },   

            handleSelect: function(item)
            {
                console.log(item);
            },

            async onLogout()
            {
                settings.logout();
                this.$router.push("/");
            }
        },
    mounted()
    {
        this.user_type  = settings.get_user_type();
        this.user_name  = settings.get_user_name();
        this.job_title  = settings.get_job_title();
        this.version    = settings.version;
        this.getHarti();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.nav-on-top{
    z-index:1000;
}

.navbar{
    margin-top: 20px;
}

.bg-dark{
    background-color: #152e33 !important;
}

.navbar-dark .navbar-nav .nav-link{
    color: #44b0cc;
    text-transform: uppercase;
    font-size: 12px;
}

.navbar-dark .active .nav-link{
    background-color: #23a455;
    color: white;
}

.version{
    color: lightgreen;
}

.harta a {
    text-decoration: none;
}

</style>
