<template>
    <el-autocomplete
        v-model="NumeProiectMutable"
        :fetch-suggestions="querySearch"
        placeholder="Cauta proiect"
        @select="handleProiectSelect"
        @input="onManualinput"
        :disabled="dezactivat"
        class="full-width">
        
        <template slot-scope="{ item }"> 
            <div class="value">{{ item.Nume }}</div>
        </template>
    </el-autocomplete>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage'

    export default {
        name: "SelectProiect",
        extends: BasePage,
        props: {
            value: {
                required: true,
            },            
            IdProiect:{
                required:false
            },           
            dezactivat: {
                required: false,
                default () {
                    return false
                }
            },
            ArataOptiuneToate: {
                required: false,
                type: Boolean,
                default () {
                    return false
                }
            }           
        },
        
        computed: {
            NumeProiectMutable: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
        watch: {
            NumeProiectMutable: function(newVal) {
                this.$emit('input', newVal)
            },
            NumeProiect: function(newVal) {
                this.$set(this, 'NumeProiectMutable', newVal)
            }
        },
        data(){
            return {
                lastSelected: null
            }        
        },
                
        methods: {
            async querySearch(queryString, cb) {
                if (!queryString) {
                    queryString = ''
                }
            
                var response = await this.post('proiecte/search_nume_proiect', {
                    queryString: queryString, ArataOptiuneToate: this.ArataOptiuneToate
                })
                cb(response.Proiecte)
            },
            handleProiectSelect: function (item) {               
                var proiectObj          = item.ProiectComplet
                this.lastSelected       = proiectObj
                this.NumeProiectMutable = item.Nume
                
                this.$emit('update:IdProiect',item.id)                
                this.$emit('selectie', item)
            },
            onManualinput(value) {
                if (this.lastSelected && value == this.lastSelected.Nume) {
                    this.$emit('update:IdProiect', this.lastSelected.id)                 
                    return
                }

                this.$emit('update:IdProiect', undefined)              
            }
        },
        mounted(){  
        } 
    }
</script>

<style lang="less" scoped>
  .full-width{
    width: 100%;
  }

  .el-autocomplete-suggestion li {
    border-bottom:1px solid #b4b4b4;
  }

</style>
