import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import crypto from 'crypto'
import settings from "./backend/LocalSettings";
import VueResource from "vue-resource";
import locale from "element-ui/lib/locale/lang/en"
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import BootstrapVue from 'bootstrap-vue'
import moment from 'moment';
import numeral from 'numeral'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import JQuery from 'jquery'
window.$ = JQuery
window.jQuery = require('jquery')

// import "fullcalendar/dist/fullcalendar.min.css";

import SelectAngajat from '@/components/SelectAngajat'
import SelectDepartament from '@/components/SelectDepartament'
import SelectOre from '@/components/SelectOre'
import SelectProiect from '@/components/SelectProiect'

Vue.component('SelectOre', SelectOre);
Vue.component('SelectAngajat', SelectAngajat);
Vue.component('SelectProiect', SelectProiect);
Vue.component('SelectDepartament', SelectDepartament)

Vue.use({
  install: function(Vue, options){
      Vue.prototype.$jQuery = require('jquery'); // you'll have this.$jQuery anywhere in your vue project
  }
})

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(ElementUI, { locale });

Vue.http.options.xhr = { withCredentials: true };
Vue.http.options.emulateJSON = true;
Vue.http.headers.common["Token"] = settings.get_token();

if (window.location.href.includes('192.168.88.101')){ 
  settings.BASE_URL = settings.BASE_URL_PROD
}
if (window.location.href.includes('ananp.brk-dev.ro')){
  settings.BASE_URL = settings.BASE_URL_DEV
}

Vue.http.options.root = settings.BASE_URL;
export const EventBus = new Vue();

Vue.filter( 'momentformat', function(value, format = 'DD/MM/YYYY') {
    var moment = require('moment')
        if (value) {
        return moment(value).format(format)
        } else {
        return null
        }
} );

  Vue.filter( 'momentformat', function(value){
    return value;
  });
  
  Vue.filter( 'format-date', function(value){
    return moment(value, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");
  } );
  
  Vue.filter( 'format-date-time', function(value){
    return moment(value, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm:ss");
  } );

  Vue.filter('format_money', function (value) {
    if (value !== undefined) {
      var nr = parseFloat(value)
      return numeral(nr).format('0,0.00')
    } else {
      return null
    }
  });
  
  Vue.filter('format_quantity', function (value) {
    if (value !== undefined) {
      var nr = parseFloat(value)
      return numeral(nr).format('0,0.00')
    } else {
      return null
    }
  });

Vue.prototype.$has_right = function (rightKey)
{
    var ret             = false;
    var drepturiDecoded = settings.get_drepturi();
    var numarUnic       = (drepturiDecoded.length - 1) * (458 + 73 - 23)
    var caractereUnice  = 'nimic' + numarUnic
    var verificareCrc   = crypto.createHash('md5').update(Buffer.from(caractereUnice)).digest('hex')
    if (drepturiDecoded.indexOf(verificareCrc) !== -1)
    {
        var indexDrept = drepturiDecoded.indexOf(rightKey)
        if (indexDrept !== -1) ret = true;
    }
    return ret;
}

Vue.prototype.$is_user_type = function(user_type){

  for(let tip of settings.get_user_type()) {
    if(user_type == tip ) return true;
  }

  return false;
};


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
